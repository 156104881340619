import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Right } from './icons';

export default function Job({ className, location, title, info, closeDate, url, invert }) {
  const black = invert ? 'var(--white)' : 'var(--black)';

  return (
    <div
      className={className}
      css={css`
        position: relative;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: var(--margin1) var(--gutter);
        color: ${black};
        padding: 2rem 0 1.2rem;
        border-top: 4px solid ${black};
        margin-bottom: var(--margin6);

        @media (max-width: 800px) {
          gap: var(--gutterHalf) var(--gutterHalf);
        }

        @media (max-width: 550px) {
          grid-template-columns: repeat(8, 1fr);
          gap: 0 var(--margin1);
          border-top-width: 3px;
          margin-bottom: var(--gutter);
        }

        a,
        a:visited {
          color: ${black};
        }

        h4,
        div {
          grid-column-start: span 4;

          @media (max-width: 1024px) {
            grid-column-start: span 6;
          }

          @media (max-width: 550px) {
            grid-column-start: span 8;
          }
        }
      `}
    >
      <h5
        className="type--mono-heading"
        css={css`
          grid-column-start: span 12;

          @media (max-width: 550px) {
            grid-column-start: span 8;
            padding-right: var(--margin8);
            margin-bottom: 2rem;
          }
        `}
      >
        {location}
      </h5>
      <h4 
        className="type--paragraph"
        css={css`
          @media (max-width: 550px) {
            font-size: 2.8rem !important;
            line-height: 1.21 !important;
            letter-spacing: -0.03rem !important;
            padding-right: 5rem;
            margin-bottom: 1.3rem;
          }
        `}
      >{title}</h4>
      <div
        className="type--paragraph"
        css={css`
          padding-left: 4.6rem;

          @media (max-width: 1024px) {
            text-align: right;
          }

          @media (max-width: 550px) {
            font-size: 2.8rem !important;
            line-height: 1.21 !important;
            letter-spacing: -0.03rem !important;
            text-align: left;
            padding-left: 0;
          }
        `}
      >
        {info}
      </div>
      <div
        className="type--paragraph"
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          @media (max-width: 1024px) {
            align-items: center;
            grid-column-start: span 12 !important;
          }

          @media (max-width: 550px) {
            grid-column-start: span 8 !important;
            font-size: 2.8rem !important;
            line-height: 1.21 !important;
            letter-spacing: -0.03rem !important;
          }
        `}
      >
        {(closeDate) ? 'Applications Close:' : 'Applications Open'}{' '}
        <br
          css={css`
            @media (max-width: 1024px) {
              display: none;
            }
          `}
        />
        {closeDate}
        <Right
          url={url}
          invert={true}
          css={css`
            margin-top: 0.4rem;

            @media (max-width: 1024px) {
              margin-left: var(--gutter);
              margin-top: 0;
            }

            @media (max-width: 550px) {
              position: absolute;
              top: 1.2rem;
              right: 0;
              margin: 0;
            }
          `}
        />
      </div>
    </div>
  );
}
